// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.navbar-brand {
    background-image: url('/assets/img/logo-white.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 400px;
    height: 40px;
}
.scrolled .navbar-brand {
    background-image: url('/assets/img/logo-dark.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 400px;
    height: 40px;
}